<template>
	<div class="notice-page">
		<div class="search-module mb20">
			<a-input-search v-model="searchInfo" placeholder="请输入标题" enter-button="搜索" size="large" :loading="searchIng"  @search="onSearch" />
		</div>
		<div class="control-box flex flex-end mb20">
			<a-button type="primary" @click="toAdd">
				新增
			 </a-button>
		</div>
		<div class="table-box">
			<a-table
				:rowKey="(item,index) => {return index}" 
				:loading="dataLoading" 
				:columns="columns"
				:data-source="list"
				:pagination="{hideOnSinglePage: true, current: page.current, total: page.total}"
				:bordered="true">
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="toEdit(item.airticle_id)">
							编辑
						</a-button>
						<a-popconfirm
						    title="确认删除该用户"
						    ok-text="是"
						    cancel-text="否"
						    @confirm="deleteNews(item.airticle_id)"
						  >
							<a-button size="small" type="danger">
								删除
							 </a-button>
						  </a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>
	</div>
</template>

<script>
	let columns = [{
		title: 'ID',
		key: 'id',
		dataIndex: 'id'
	},{
		title: '标题',
		key: 'title',
		dataIndex: 'title'
	},{
		title: '排序',
		key: 'sort',
		dataIndex: 'sort'
	},{
		title: '创建时间',
		key: 'create_at_str',
		dataIndex: 'create_at_str'
	},{
		title: '操作',
		dataIndex: 'operation',
		width: '128px',
		scopedSlots: { customRender: 'operation' }
	}]
	
	let sendOption = [{
		label: '全部用户',
		value: 1
	},{
		label: '指定发送',
		value: 2
	},{
		label: '批量发送',
		value: 3
	}]
	export default {
		data() {
			return {
				searchInfo:'',
				searchIng: false,
				columns: columns,
				dataLoading: false,
				list:[],
				page:{
					current: 1,
					total: 0
				},
				sendOption:sendOption,
				sendShow: false,
				sendIng: false,
				sendParam: {
					type: 1,
					customer_id: ''
				},
				seachUserData: [],
				fetching: false
			}
		},
		computed: {},
		created() {
			
		},
		mounted() {
			this.getData()
		},
		methods:{
			onSearch() {
				this.searchIng = true
				this.getData()
			},
			showSendModal(item) {
				this.sendParam.airticle_id = item.airticle_id
				this.sendShow = true
			},
			getData() {
				let t = this
				t.dataLoading = true
				t.$get({
					port: 'a',
					url: 'airticleList',
					data: {
						type: 2,
						page: t.page.current,
						searchInfo: t.searchInfo
					}
				}).then(res => {
					t.dataLoading = false
					t.searchIng = false
					let { code, data, msg } = res
					if (code == 0) {
						for(let i = 0; i < data.list.length;i++) {
							data.list[i].content = data.list[i].content.split(',')
						}
						t.list = data.list
						t.page.total = data.total
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.dataLoading = false
					t.searchIng = false
				})
			},
			sendMsg() {
				
			},
			findUser(value) {
				console.log(value)
				let t = this
				if(t.fetching) {
					return
				}
				t.fetching = true
				t.$get({
					port: 'a',
					url: 'customerlist',
					data: {
						searchInfo: value,
						page: 1,
						limit: 1000000
					}
				}).then(res => {
					t.fetching = false
					let { code, data, msg } = res
					if (code == 0) {
						t.seachUserData = data.list
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.fetching = false
				})
			},
			toAdd() {
				this.$router.push({path:'/news/addNews'})
			},
			toEdit(id) {
				console.log(id)
				this.$router.push({path:'/news/addNews', query:{airticle_id: id}})
			},
			setUser(value) {
				this.sendParam.customer_id = value
			},
			deleteNews(id) {
				let t = this
				t.$get({
					port: 'a',
					url: 'airticleDel',
					data: {airticle_id: id}
				}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success(msg, 1.5)
						t.getData()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			}
		}
	}
</script>

<style lang="less">
</style>
